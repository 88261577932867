<template>
  <div>
    <div class="news f30 tc">
      {{$t('licence.News6Title')}}
    </div>
    <div class="line"></div>
    <div class="tc mb-40">
      <img
        src="@/assets/images/banner-4.png"
        height="480"
        alt=""
      />
    </div>
    <div class="p-0-250 f22">
      <div>{{$t('licence.News6T1')}}</div>
      <div>
        {{$t('licence.News6Part1')}}
      </div>
      <div>{{$t('licence.News6Part2')}}</div>
      <div>{{$t('licence.News6Part3')}}</div>
      <div>
        {{$t('licence.News6Part4')}}
      </div>
      <div>{{$t('licence.News6Part5')}}</div>
      <div>
        {{$t('licence.News6Part6')}}
      </div>
      <div>{{$t('licence.News6Part7')}}</div>
      <div>{{$t('licence.News6Part8')}}</div>
      <div>{{$t('licence.News6Part9')}}</div>
      <div>{{$t('licence.News6Part10')}}</div>
      <div>{{$t('licence.News6Part11')}}</div>
      <div>{{$t('licence.News6Part12')}}</div>
      <div>{{$t('licence.News6Part13')}}</div>
      <div>
        {{$t('licence.News6Part14')}}
      </div>
      <div>
        {{$t('licence.News6Part15')}}
      </div>
      <div>
        {{$t('licence.News6Part16')}}
      </div>
      <div>{{$t('licence.News6Part17')}}</div>
      <div>{{$t('licence.News6Part18')}}</div>
      <div>{{$t('licence.News6Part19')}}</div>
      <div>{{$t('licence.News6Part20')}}</div>
      <div>{{$t('licence.News6Part21')}}</div>
      <div>{{$t('licence.News6Part22')}}</div>
      <div>{{$t('licence.News6Part23')}}</div>
      <div>{{$t('licence.News6Part24')}}</div>
      <div>{{$t('licence.News6Part25')}}</div>
      <div class="tc">
        <img
          @click="open"
          src="@/assets/images/news6-1.png"
          height="560"
          alt=""
        />
      </div>
      <div
        class="disflex p20"
        style="border: 1px solid #eaeaea; margin-top: 150px"
      >
        <div>
          <div class="f20">
            中国卫生信息与健康医疗大数据学会肾脏病专业委员会关于召开2021年度学术年会暨中国肾脏病大数据协作网高峰论坛会议通知
          </div>
          <div
            class="disflex f18"
            style="color: #707070"
          >
            <div class="mr-40">
              <a-icon
                type="clock-circle"
                class="mr-5"
              />2021-05-14
            </div>
            <div class="mr-40">
              <a-icon
                type="file"
                class="mr-5"
              />1.1MB
            </div>
            <div>
              <a-icon
                type="file-pdf"
                class="mr-5"
              />pdf
            </div>
          </div>
        </div>
        <a
          class="download tc f18"
          href="/static/中国卫生信息与健康医疗大数据学会肾脏病专业委员会关于召开2021年度学术年会暨中国肾脏病大数据协作网高峰论坛会议通知.pdf"
        ><a-icon class="mr-5" type="download" />{{$t('licence.download')}}</a>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import myFooter from "@/components/footer.vue";

export default {
  components: {
    myFooter,
  },
  methods: {
    open() {
      //   let link = document.createElement("a");
      //   link.setAttribute("download", "中国卫生信息与健康医疗大数据学会肾脏病专业委员会关于召开2021年度学术年会暨中国肾脏病大数据协作网高峰论坛会议通知.pdf");
      //   link.href = "@/assets/file/中国卫生信息与健康医疗大数据学会肾脏病专业委员会关于召开2021年度学术年会暨中国肾脏病大数据协作网高峰论坛会议通知.pdf";
      //   link.click();
    },
    goLink(name) {
      this.$router.push({ name: name });
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  border-bottom: 3px solid #1f368b;
  margin: 0 250px 50px;
}
.img-title {
  color: rgba(0, 0, 0, 0.6);
}
.news {
  padding: 70px 250px 50px;
}
.p-0-250 {
  padding: 0 250px;
}
.mytext {
  text-indent: 30px;
}
.download {
  color: #fff;
  width: 120px;
  padding: 5px;
  background-color: #33B5EE;
  margin-left:50px;
  flex-shrink: 0;
}
</style>
